import {createSlice} from '@reduxjs/toolkit';

const sidebarToggleSlice = createSlice({
      name: "toggle",
      initialState: {
        value: false,
      },
      reducers: {
         toggleHandler: (state,action)=> {
            state.value = action.payload;
         }
      }
});

export const {toggleHandler} = sidebarToggleSlice.actions;
export default sidebarToggleSlice.reducer;