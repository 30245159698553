import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import giftIcon from '../images/icons/gift.png';
import calendarIcon from '../images/icons/calendar.png';
import moment from 'moment-timezone';
import { FaChevronLeft,FaEnvelope } from "react-icons/fa";
import {FiGlobe} from 'react-icons/fi';

const HeaderDiv = styled.div`
   padding: 10px 20px;
   display:flex;
   justify-content: space-between;
   align-items: center;
   background-color: ${props => props.theme.primary};

   img {
     width: 60px;
   }
`

const CalendarDiv = styled.div`
     display: inline-block;
     border: none;
     outline: none;
     background-color: transparent;
     cursor: pointer !important;
     position: relative;
`
const Header = ({status}) => {
  // let date = moment().tz("Asia/Yangon").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(moment().tz("Asia/Yangon").toDate());
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <div onClick={onClick} ref={ref}>
          <img src={calendarIcon} alt="mes" style={{width: "30px"}}/>
      </div>
  ));
  const navigate = useNavigate();

  return (
    <HeaderDiv>
        <div style={{display: 'flex'}}>
            {
              status &&
              <FaChevronLeft size="16px" style={{marginRight: '15px'}} color="#FFF" onClick={()=>navigate('/')} cursor="pointer" />
            }
            <h5 style={{margin: 0, fontSize: '18px', fontWeight: 'bolder', color: '#FFF'}}>Royal Thai</h5>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'flex-end'
        }}>
          <CalendarDiv>
              <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      navigate(`/result/${date.getFullYear()}-${date.getMonth()+1 < 10?`0${date.getMonth()+1}` : date.getMonth()+1}-${date.getDate() < 10 ? '0'+date.getDate(): date.getDate()}`)
                    }}
                    customInput={<ExampleCustomInput />}
              />
          </CalendarDiv>

          

        </div>
    </HeaderDiv>
  )
}

export default Header;