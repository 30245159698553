import React from 'react';
import styled from 'styled-components';

const EmptyDiv = styled.div`
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: ${props => props.theme.fontColor};
`

const EmptyRecord = ({message}) => {
  return (
    <EmptyDiv>
        {message}
    </EmptyDiv>
  )
}

export default EmptyRecord;