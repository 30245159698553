import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import {liveHandler} from '../store/liveNumSlice';
import {io} from "socket.io-client";
import { getMethod } from '../Apis/apis';
import { FiCheck,FiClock } from 'react-icons/fi';

const ContainerDiv = styled.div`
   position: relative;
   background-color: ${props => props.theme.secondary};
`
const TextBlink = keyframes`
    50% {
        opacity: 1;
    }
    57% {
        opacity: 0;
    }
    64% {
        opacity: 1;
    }
    71% {
        opacity: 0;
    }
    78% {
        opacity: 1;
    }
`

const LiveNumber = styled.h1`
   text-align: center;
   user-select: none;
   color: ${props => props.theme.fontColor};
   font-size: 70px;
   font-weight: bolder;
   text-shadow: 0 4.36px 8.896px #353535, 0 -2px 1px #fff;
   margin-bottom: 10px;
`
const LiveNumberAnimation = styled.h1`
   text-align: center;
   user-select: none;
   color: ${props => props.theme.fontColor};
   font-size: 80px;
   font-weight: bolder;
   text-shadow: 0 4.36px 8.896px #353535, 0 -2px 1px #fff;
   margin-bottom: 10px;
   animation: ${TextBlink} 4s linear infinite;
`

const SetValueContainer = styled.div`
   border-radius: 10px;
   padding: 10px 20px;
`
const UpdatedIcon = styled.span`
   margin-right: 5px;
`

const DateTimeDiv = styled.div`
    padding: 0 25px;
    color: #FFF;
    p {
        margin-bottom: 0;
        text-transform: uppercase;
        text-align: center;
    }
`

const UpdatedDate = styled.p`
    margin-top: 15px;
    margin-bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
`
let currentDate = moment().tz("Asia/Yangon").format("DD-MM-YYYY");
let updatedDate = moment().tz("Asia/Yangon").format("MMMM DD, YYYY");
let day = moment().tz("Asia/Yangon").format("dddd,");

const Live = () => {
    const [animate9,setAnimate9] = useState(false);
    const [animate11,setAnimate11] = useState(false);
    const [animate12,setAnimate12] = useState(false);
    const [animate14,setAnimate14] = useState(false);
    const [animate15,setAnimate15] = useState(false);
    const [animate17,setAnimate17] = useState(false);
    const [animate18,setAnimate18] = useState(false);
    const [animate20,setAnimate20] = useState(false);
    const [animate21,setAnimate21] = useState(false);

    const [check9,setCheck9] = useState(false);
    const [check11,setCheck11] = useState(false);
    const [check12,setCheck12] = useState(false);
    const [check14,setCheck14] = useState(false);
    const [check15,setCheck15] = useState(false);
    const [check17,setCheck17] = useState(false);
    const [check18,setCheck18] = useState(false);
    const [check20,setCheck20] = useState(false);
    const [check21,setCheck21] = useState(false);

    const [result,setResult] = useState("--");
    const dispatch = useDispatch();
    const [serverTime,setServerTime] = useState();
    let date = moment().tz("Asia/Yangon").format("YYYY-MM-DD");
    const [resultTime,setResultTime] = useState("??");
    
    useEffect(()=>{
        const socket = io(`${process.env.REACT_APP_HOST}/time`);
        socket.connect();
        socket.on("STime", res => {
            setServerTime(res)
            return () => {
                socket.disconnect();
            };
        });
    },[]);

    useEffect(()=>{
        const socket = io(`${process.env.REACT_APP_HOST}/live`);
        socket.connect();
        socket.on("live", async res => {
                let timeRes = await getMethod(`/number/time`);
                if(timeRes){
                    if(timeRes.con){
                        if(timeRes.data > "08:00:00" && timeRes.data < "09:29:58"){
                            if(res){
                                setResult(res.result);
                                dispatch(liveHandler(res.result));
                            };
                        };
                        
                        if(timeRes.data > "09:35:00" && timeRes.data < "10:59:58"){
                            if(res){
                                setResult(res.result);
                                dispatch(liveHandler(res.result));
                            }
                        };

                        if(timeRes.data > "11:05:00" && timeRes.data < "12:29:58"){
                            if(res){
                                setResult(res.result);
                                dispatch(liveHandler(res.result));
                            }
                        }

                        if(timeRes.data > "12:35:00" && timeRes.data < "13:59:58"){
                            if(res){
                                setResult(res.result);
                                dispatch(liveHandler(res.result));
                            }
                        }

                        if(timeRes.data > "14:05:00" && timeRes.data < "15:29:58"){
                            if(res){
                                setResult(res.result);
                                dispatch(liveHandler(res.result));
                            }
                        };

                        if(timeRes.data > "15:35:00" && timeRes.data < "16:59:58"){
                            if(res){
                                setResult(res.result);
                                dispatch(liveHandler(res.result));
                            }
                        };

                        if(timeRes.data > "17:05:00" && timeRes.data < "18:29:58"){
                            if(res){
                                setResult(res.result);
                                dispatch(liveHandler(res.result));
                            }
                        };

                        if(timeRes.data > "18:35:00" && timeRes.data < "19:59:58"){
                            if(res){
                                setResult(res.result);
                                dispatch(liveHandler(res.result));
                            }
                        };

                        if(timeRes.data > "20:05:00" && timeRes.data < "21:29:58"){
                            if(res){
                                setResult(res.result);
                                dispatch(liveHandler(res.result));
                            }
                        };
                    };
                };     
        });
        return () => {
            socket.disconnect();
        };
    },[]);

    useEffect(()=>{

        let nine = "08:00:00";
        let endNine = "09:29:58";
        
        let eleven = "09:35:00";
        let endEleven = "10:59:58";

        let twelve = "11:05:00";
        let endTwelve = "12:29:58";

        let two = "12:35:00";
        let endTwo = "13:59:58";

        let three = "14:05:00";
        let endThree = "15:29:58";

        let five = "15:35:00";
        let endFive = "16:59:58";

        let six = "17:05:00";
        let endSix = "18:29:58";

        let eight = "18:35:00";
        let endEight = "19:59:58";

        let nightNine = "20:05:00";
        let endNightNine = "21:29:58";
    
        if(serverTime > "09:29:59" && serverTime < "09:34:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "09:30 AM"){
                                setResult(el.result);
                            }
                        })
                    }
                };
            })();
        };

        if(serverTime > "10:59:59" && serverTime < "11:04:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "11:00 AM"){
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(serverTime > "12:29:59" && serverTime < "12:34:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "12:30 PM"){
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(serverTime > "13:59:59" && serverTime < "14:04:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "02:00 PM"){
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(serverTime > "15:29:59" && serverTime < "15:34:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "03:30 PM"){
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(serverTime > "16:59:59" && serverTime < "17:04:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "07:00 PM"){
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(serverTime > "18:29:59" && serverTime < "18:34:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "06:30 PM"){
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(serverTime > "19:59:59" && serverTime < "20:04:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "08:00 PM"){
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(serverTime > "21:29:59" && serverTime < "23:59:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "09:30 PM"){
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if (serverTime > nine && serverTime < endNine){
            setAnimate9(true);
            setCheck9(true);
            setResultTime("09:30 AM");
        }else{
            setAnimate9(false);
            setCheck9(false);
        };

        if (serverTime > eleven && serverTime < endEleven){
            setAnimate11(true);
            setCheck11(true);
            setResultTime("11:00 AM");
        }else{
            setAnimate11(false);
            setCheck11(false);
        };

        if (serverTime > twelve && serverTime < endTwelve){
            setAnimate12(true);
            setCheck12(true);
            setResultTime("12:30 PM");
        }else{
            setAnimate12(false);
            setCheck12(false);
        };

        if (serverTime > two && serverTime < endTwo){
            setAnimate14(true);
            setCheck14(true);
            setResultTime("02:00 PM");
        }else{
            setAnimate14(false);
            setCheck14(false);
        };

        if (serverTime > three && serverTime < endThree){
            setAnimate15(true);
            setCheck15(true);
            setResultTime("03:30 PM");
        }else{
            setAnimate15(false);
            setCheck15(false);
        };

        if (serverTime > five && serverTime < endFive){
            setAnimate17(true);
            setCheck17(true);
            setResultTime("05:00 PM");
        }else{
            setAnimate17(false);
            setCheck17(false);
        };

        if (serverTime > six && serverTime < endSix){
            setAnimate18(true);
            setCheck18(true);
            setResultTime("06:30 PM");
        }else{
            setAnimate18(false);
            setCheck18(false);
        };

        if (serverTime > eight && serverTime < endEight){
            setAnimate20(true);
            setCheck20(true);
            setResultTime("08:00 PM");
        }else{
            setAnimate20(false);
            setCheck20(false);
        };

        if (serverTime > nightNine && serverTime < endNightNine){
            setAnimate21(true);
            setCheck21(true);
            setResultTime("09:30 PM");
        }else{
            setAnimate21(false);
            setCheck21(false);
        };
    },[serverTime]);

  return (
    <>
        <ContainerDiv>
            <SetValueContainer>
                <div style={{
                    width: '100%'
                }}>
                    {
                        animate9|| animate11 || animate12 || animate14 || animate15 || animate17 || animate18 || animate20 || animate21 ? <LiveNumberAnimation>{result}</LiveNumberAnimation> : <LiveNumber>{result}</LiveNumber>
                    }
                </div>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <DateTimeDiv>
                        <p>Date</p>
                        <p
                        style={{
                            color:"#f88c49",
                            fontSize: '14px',
                            marginTop: '5px',
                        }}
                        >{currentDate}</p>
                    </DateTimeDiv>
                    <DateTimeDiv>
                        <p>section</p>
                        <p
                        style={{
                            color:"#f88c49",
                            fontSize: '14px',
                            marginTop: '5px'
                        }}
                        >{resultTime}</p>
                    </DateTimeDiv>
                </div>
            </SetValueContainer>
        </ContainerDiv>
        <UpdatedDate>
             <div style={{marginRight: '5px', display: 'flex',alignItems: 'center'}}> 
                {false ? null : 
                    <UpdatedIcon>
                        {
                            (check9 || check11 || check12 || check14 || check15 || check17 || check18 || check20 || check21)?
                            <FiClock color="#f88c49" size="20px" />
                            :
                            <FiCheck color="#f88c49" size="20px" />
                        }
                    </UpdatedIcon>
                }
            </div>
            <span style={{fontSize: '14px'}}>{day} {updatedDate} {serverTime ?? '21:30:04'}</span>
        </UpdatedDate>
    </>
  )
}

export default Live
