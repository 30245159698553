import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { getMethod } from '../Apis/apis';
import Close from '../utils/Close';
import Header from "./Header";
import Home from './Home';

const Container = styled.div`
    max-width: 480px;
    height: 100vh;
    overflow: scroll;
    opacity: 1;
    margin: 0 auto;
    position: relative;
    background-color: ${props => props.theme.primary};
    
`

const Main = () => {
  const [close, setClose] = useState(true);
  useEffect(()=>{
    (async () =>{
        let res = await getMethod('/close');
        if(res){
          if(res.data.length > 0){
                if(res.data[0].status){
                    setClose(res.data[0].status);
                }else{
                    setClose(res.data[0].status);
                }
          }
        }
    })();
  },[]);
  return (
    <Container>
        <Header />
        {
          close ?
          <Home /> :
          <Close />
        }
    </Container>
  )
}

export default Main;