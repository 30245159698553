import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import {io} from "socket.io-client";
import {getMethod} from '../Apis/apis';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

const ContainerDiv = styled.div`
   padding: 10px;
`
const Ul = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
`
const Li = styled.li`
    padding: 5px;
    user-select: none;
    width: 100%;
    
    &:last-child {
        margin-bottom: 0;
    }
`
const ResultNum = styled.p`
    font-weight: bolder;
    color: ${props => props.theme.fontColor};
    font-size: 35px;
    letter-spacing: .6px;
    margin-bottom: 0;
    background-color: ${props => props.theme.secondary};
`

const TimeResultFlex = styled.div`
    display: flex;
    align-items: center; 
    color: #FFF;

    span {
      background-color: ${props => props.theme.secondary};
      color: #FFF;
      font-size: 25px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      text-align: center;
      padding: 17px;
      div {
        p {
          margin-bottom: 0;
          font-size: 16px;
          text-align: center;
        }
      } 
    } 
`


const DailyResult = () => {
    const [result9,setResult9] = useState("??");
    const [result11,setResult11] = useState("??");
    const [result12,setResult12] = useState("??");
    const [result14,setResult14] = useState("??");
    const [result15,setResult15] = useState("??");
    const [result17,setResult17] = useState("??");
    const [result18,setResult18] = useState("??");
    const [result20,setResult20] = useState("??");
    const [result21,setResult21] = useState("??");
    let currentDate = moment().tz("Asia/Yangon").format("YYYY-MM-DD");
 

    useEffect(()=>{
       const fetch = async () => {
        let res = await getMethod(`/number/filter?date=${currentDate}`);
            if(res){
               if(res.data){
                if(res.data.length > 0){
                  res.data.map(obj => {
                    if(obj.time === "09:30 AM"){
                      setResult9(obj.result);
                    };

                    if(obj.time === "11:00 AM"){
                      setResult11(obj.result ?? '??');
                    };

                    if(obj.time === "12:30 PM"){
                      setResult12(obj.result ?? '??');
                    };
  
                   if(obj.time === "02:00 PM"){
                     setResult14(obj.result ?? '??');
                   };

                   if(obj.time === "03:30 PM"){
                      setResult15(obj.result ?? '??');
                    };
  
                   if(obj.time === "05:00 PM"){
                     setResult17(obj.result ?? '??');
                   };

                   if(obj.time === "06:30 PM"){
                      setResult18(obj.result ?? '??');
                   };

                   if(obj.time === "08:00 PM"){
                      setResult20(obj.result ?? '??');
                   };

                   if(obj.time === "09:30 PM"){
                      setResult21(obj.result ?? '??');
                   };
                  })
                };
               }
            }
       };
       fetch();
    },[]);
    
    useEffect(
      () => {
        const socket = io(`${process.env.REACT_APP_HOST}/live`);
        socket.connect();
        socket.on("result", res => {
            if(res){
              switch(res.time) {
                case "09:30 AM":
                  setResult9(res.result);
                  break;
                case "11:00 AM":
                  setResult11(res.result);
                  break;
                case "12:30 PM":
                  setResult12(res.result);
                  break;
                case "02:00 PM":
                  setResult14(res.result);
                  break;
                case "03:30 PM":
                  setResult15(res.result);
                  break;
                case "05:00 PM":
                  setResult17(res.result);
                  break;
                case "06:30 PM":
                  setResult18(res.result);
                  break;
                case "08:00 PM":
                  setResult20(res.result);
                  break;
                case "09:30 PM":
                  setResult21(res.result);
                  break;
                default:
                  return;
              };
            }
        });
        return () => {
          socket.disconnect();
        }
      },[]
    );


  return (
    <ContainerDiv>
       <Ul>
          <Li>
            <TimeResultFlex>
                <span>
                  <div>
                    <p>09:30</p>
                    <p>AM</p>
                  </div>
                  <ResultNum>{result9}</ResultNum>
                </span>
            </TimeResultFlex>
          </Li>
          <Li>
            <TimeResultFlex>
                <span>
                  <div>
                    <p>11:00</p>
                    <p>AM</p>
                  </div>
                  <ResultNum>{result11}</ResultNum>
                </span>
            </TimeResultFlex>
          </Li>
       </Ul>
       <Ul>
          <Li>
            <TimeResultFlex>
                <span>
                  <div>
                    <p>12:30</p>
                    <p>PM</p>
                  </div>
                  <ResultNum>{result12}</ResultNum>
                </span>
            </TimeResultFlex>
          </Li>
          <Li>
            <TimeResultFlex>
                <span>
                  <div>
                    <p>02:00</p>
                    <p>PM</p>
                  </div>
                  <ResultNum>{result14}</ResultNum>
                </span>
            </TimeResultFlex>
          </Li>
       </Ul>
       <Ul>
          <Li>
            <TimeResultFlex>
                <span>
                  <div>
                    <p>03:30</p>
                    <p>PM</p>
                  </div>
                  <ResultNum>{result15}</ResultNum>
                </span>
            </TimeResultFlex>
          </Li>
          <Li>
            <TimeResultFlex>
                <span>
                  <div>
                    <p>05:00</p>
                    <p>PM</p>
                  </div>
                  <ResultNum>{result17}</ResultNum>
                </span>
            </TimeResultFlex>
          </Li>
       </Ul>
       <Ul>
          <Li>
            <TimeResultFlex>
                <span>
                  <div>
                    <p>06:30</p>
                    <p>PM</p>
                  </div>
                  <ResultNum>{result18}</ResultNum>
                </span>
            </TimeResultFlex>
          </Li>
          <Li>
            <TimeResultFlex>
                <span>
                  <div>
                    <p>08:00</p>
                    <p>PM</p>
                  </div>
                  <ResultNum>{result20}</ResultNum>
                </span>
            </TimeResultFlex>
          </Li>
       </Ul>
       <Ul>
          <Li>
            <TimeResultFlex>
                <span>
                  <div>
                    <p>09:30</p>
                    <p>PM</p>
                  </div>
                  <ResultNum>{result21}</ResultNum>
                </span>
            </TimeResultFlex>
          </Li>
          <Li>
            {/* <TimeResultFlex>
                <span>
                  <div>
                    <p>02:00</p>
                    <p>PM</p>
                  </div>
                  <ResultNum>{result14}</ResultNum>
                </span>
            </TimeResultFlex> */}
          </Li>
       </Ul>
    </ContainerDiv>
  )
}

export default DailyResult
