import React, { useState } from 'react';
import styled from 'styled-components';
import DailyResult from './DailyResult';
import Live from './Live';

const Home = () => {


  return (
    <>
      <Live />
      <DailyResult />
    </>
  )
}

export default Home
