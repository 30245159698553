import React from 'react'
import styled from 'styled-components';
import { ProSidebar, Menu, MenuItem,SidebarHeader, SidebarFooter, SidebarContent  } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FiHome,FiMessageSquare,FiX,FiKey,FiInfo } from "react-icons/fi";
import {IoIosCreate} from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion"
import { toggleHandler } from '../../store/sidebarToggleSlice';

const SidebarHeaderDiv = styled.div`
    padding: 24px; 
    text-transform: uppercase; 
    font-weight: bold; 
    font-size: 14px; 
    letter-spacing: 1px; 
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const CloseIcon = styled.div`

`;

const ToggleSidebar = styled.div`
    position: fixed;
    height: 100vh;
    z-index: 999;
`

const Sidebar = () => {
  const sidebarVariants = {
    open: { opacity: 1, x: 0 , display: 'block', zIndex:'999'},
    closed: { opacity: 0, x: "-100%", display: 'none' },
  }
  const dropVariants = {
    open: { opacity: 1, display: 'block', zIndex:'997'},
    closed: { opacity: 0, display: 'none' },
  }
  const dispatch = useDispatch();
  const {value} = useSelector(state => state.sideBarToggle);
  const sidebarToggleHandler = () => {
    dispatch(toggleHandler(!value));
  }
  return (
    <>
        <motion.div
            animate={value ? "open" : "closed"}
            variants={sidebarVariants}
        >
            <ToggleSidebar>
                <ProSidebar style={{height: "100vh"}}>
                    <SidebarHeader>
                        <SidebarHeaderDiv>
                            Header
                            <CloseIcon>
                                <FiX size="25px" onClick={sidebarToggleHandler} style={{cursor: 'pointer'}}/>
                            </CloseIcon>
                        </SidebarHeaderDiv>
                    </SidebarHeader>
                    <SidebarContent>
                    <Menu iconShape="circle">
                        <MenuItem icon={<FiHome />}>
                            2D Result
                            <Link to="/admin/home" />
                        </MenuItem>
                        <MenuItem icon={<IoIosCreate />}>
                            2D Create Result
                            <Link to="/admin/create-result" />
                        </MenuItem>
                        <MenuItem icon={<FiX />}>
                            Close
                            <Link to="/admin/close" />
                        </MenuItem>
                        <MenuItem icon={<FiKey />}>
                            Change Password
                            <Link to="/admin/change-password" />
                        </MenuItem>
                        {/* <SubMenu title="Components" icon={<FiHome />}>
                            <MenuItem>Component 1</MenuItem>
                            <MenuItem>Component 2</MenuItem>
                        </SubMenu> */}
                    </Menu>
                    </SidebarContent>
                    <SidebarFooter>
                    </SidebarFooter>
                </ProSidebar>
            </ToggleSidebar>
        </motion.div>
        <motion.div
            animate={value ? "open" : "closed"}
            variants={dropVariants}
            style={{
                position: 'fixed',
                top: '0',
                right: '0',
                bottom: '0',
                left: '0',
                backgroundColor: '#00000066',
            }}
            onClick={sidebarToggleHandler}
        >
        </motion.div>
    </>
  )
}

export default Sidebar
