import React from 'react';
import {Routes,Route } from "react-router-dom";
import RouteGuard from './Apis/RouteGuard/RouteGuard';
import Changepass from './components/dashboard/Changepass';
import Close from './components/dashboard/Close';
import Home from './components/dashboard/Home';
import Login from './components/dashboard/Login';
import ResultCreate from './components/dashboard/ResultCreate';
import Main from './components/Main';
import Result from './components/Result';
import NotFound from './utils/NotFound';

function App() {
  return (
      <Routes>
          <Route path="/" exact element={
                <Main/>
           }/>
           <Route path="/result/:date" exact element={
                <Result/>
           }/>
           <Route path="/admin/home" exact element={
                <RouteGuard>
                    <Home/>
                </RouteGuard>
           }/>
           <Route path="/admin/create-result" exact element={
                <RouteGuard>
                    <ResultCreate/>
                </RouteGuard>
           }/>
           <Route path="/admin/close" exact element={
                <RouteGuard>
                    <Close/>
                </RouteGuard>
           }/>
           <Route path="/admin/change-password" exact element={
                <RouteGuard>
                    <Changepass/>
                </RouteGuard>
           }/>

           <Route path="/admin/login" exact element={
                <Login/>
           }/>
          <Route path="*" element={<NotFound />} />
      </Routes>
  );
}

export default App;
