import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom';
import styled from 'styled-components'
import { deleteMethod, getMethod, patchMethod, postMethod } from '../../Apis/apis';
import DashboardLoading from '../../utils/DashboardLoading';
import { FiEdit,FiTrash2 } from "react-icons/fi";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

const H5 = styled.h5`
    margin-bottom: 0;
    color: #333;
    font-size: 15px;
    font-weight: 700;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.1;
    position: relative;
    &:after {
        content: "";
        background-color: #d2d2d2;
        width: 60px;
        height: 1px;
        position: absolute;
        bottom: -20px;
        left: 0;
`
const ActionBtn = styled.button`
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
`

const CreateBtn = styled.button`
    padding: 8px 20px;
    border: none;
    outline: none;
    background-color: rgb(0, 161, 0);
    color: #FFF;
    border-radius: 0.25rem;
`
const Create = () => {
  const [data,setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [result,setResult] = useState("");
  const [time,setTime] = useState("");
  const closeRef = useRef();
  const closeAddRef = useRef();
  const navigate = useNavigate();
 
  useEffect(()=>{
    let token = localStorage.getItem("r_t_a");
        if(token){
            (async() => {
                let res = await getMethod(`/custom`);
                if(res){
                    if(res.data){
                        setData(res.data);
                        setLoading(false);
                    }else{
                        setLoading(false);
                    };
                };
            })();
        }else{
            navigate("/admin/login");
        };
    },[]);

const addHandler = async (e) => {
  let hideModal= document.querySelector(".modal-backdrop");
  e.preventDefault();
  let date = moment().tz("Asia/Yangon").format("YYYY-MM-DD");
  let data = {
    result,time,date
  };
  if(result.length > 1 && result.length < 3){
    let token = localStorage.getItem("r_t_a");
    if(token){
         (async ()=> {
             let res = await postMethod(`/custom`,data, token);
             if(res){
                 if(res.message === "jwt malformed"){
                     navigate("/admin/login");
                     if(hideModal){
                         hideModal.classList.remove('modal-backdrop');
                     };
                 };
                 if(res.con){
                     let updateResponse = await getMethod(`/custom`);
                     if(updateResponse){
                         setData(updateResponse.data);
                         setLoading(false);
                         setResult("");
                         closeAddRef.current.click();
                     };
                 }else{
                    if(res.message === "invalid token"){
                        navigate("/admin/login");
                        if(hideModal){
                            hideModal.classList.remove('modal-backdrop');
                        };
                        return;
                    };
                    if(res.message === "jwt expired"){
                        navigate("/admin/login");
                        if(hideModal){
                            hideModal.classList.remove('modal-backdrop');
                        };
                        return;
                    };
                    alert(res.message);
                    setLoading(false);
                 };
             }else{
                 navigate("/admin/login");
                 if(hideModal){
                     hideModal.classList.remove('modal-backdrop');
                 };
             }
         })();
    }else{
         navigate("/admin/login");
    };
  }else{
    alert("Result is must be 2")
  };
}
const deleteHandler = (id) => {
  confirmAlert({
    title: 'Delete!',
    message: 'Are you sure to do this.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => yesHander(id)
      },
      {
        label: 'No',
      }
    ]
  });
};
const yesHander = (id) => {
    setLoading(true);
    let token = localStorage.getItem("r_t_a");
    if(token){
        (async ()=> {
            let res = await deleteMethod(`/custom/${id}`,token);
            if(res){
                if(res.con){
                    setData(
                        data.filter(el => el._id !== id)
                    );
                    setLoading(false);
                }else{
                    navigate("/admin/login");
                    setLoading(false);
                };
            }
        })();
    }else{
        navigate("/admin/login");
        setLoading(false);
    };
};
const updateSubmitHandler = (id) => {
   let hideModal= document.querySelector(".modal-backdrop");
   let data = {
      result
   };
   if(result.length > 1 && result.length < 3){
    setLoading(true);
    let token = localStorage.getItem("r_t_a");
    if(token){
         (async ()=> {
             let res = await patchMethod(`/custom/edit/${id}`,data, token);
             if(res){
                 if(res.message === "jwt malformed"){
                     navigate("/admin/login");
                     if(hideModal){
                         hideModal.classList.remove('modal-backdrop');
                     };
                 };
                 if(res.con){
                     let updateResponse = await getMethod(`/custom`);
                     if(updateResponse){
                         setData(updateResponse.data);
                         setLoading(false);
                         setResult(" ");
                         closeRef.current.click();
                     };
                 }else{
                     if(res.message === "invalid token"){
                         navigate("/admin/login");
                         if(hideModal){
                             hideModal.classList.remove('modal-backdrop');
                         };
                         return;
                     };
 
                     if(res.message === "jwt expired"){
                         navigate("/admin/login");
                         if(hideModal){
                             hideModal.classList.remove('modal-backdrop');
                         };
                         return;
                     };
                     alert(res.message);
                     setLoading(false);
                 };
             }else{
                 navigate("/admin/login");
                 if(hideModal){
                     hideModal.classList.remove('modal-backdrop');
                 };
             }
         })();
    }else{
         navigate("/admin/login");
    };
   }else{
        alert("Result is must be 2")
   };
};

  return (
    <>
       <div className='card h-100'>
            <div className='card-header' style={{
                    backgroundColor: "transparent",
                    borderBottom: 'none',
                    padding: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'relative'}}>
                <H5>Create 2D</H5>
                <div>
                    <CreateBtn data-bs-toggle="modal" data-bs-target= "#model-create-result">Create</CreateBtn>
                    <div className="modal fade" id="model-create-result" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content text-dark">
                                <form onSubmit={addHandler}>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">Create</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <label htmlFor="" className="form-label">Result</label>
                                            <input type="number" step="any" value={result} className="form-control" id="" placeholder="result" onChange={(e)=> setResult(e.target.value)}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="" className="form-label">Time</label>
                                            <select className="form-control custom-select" onChange={(e)=> setTime(e.target.value)}>
                                                <option>Choose</option>
                                                <option value="09:30 AM">09:30 AM</option>
                                                <option value="11:00 AM">11:00 AM</option>
                                                <option value="12:30 PM">12:30 PM</option>
                                                <option value="02:00 PM">02:00 PM</option>
                                                <option value="03:30 PM">03:30 PM</option>
                                                <option value="05:00 PM">05:00 PM</option>
                                                <option value="06:30 PM">06:30 PM</option>
                                                <option value="08:00 PM">08:00 PM</option>
                                                <option value="09:30 PM">09:30 PM</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeAddRef}>Close</button>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className='card-body'>
                <table className="table h-100">
                        <thead className="table-dark">
                            <tr style={{
                                display:'table',
                                width:'100%',
                                tableLayout:'fixed'
                            }}>
                                <th>No.</th>
                                <th>Result</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody style={{
                            display: "block",
                            height:'90%',
                            overflow:'auto'
                        }}>
                            {
                                data.length > 0 &&
                                data.map((row,index) => 
                                    <tr key={index} 
                                    style={{
                                        display:'table',
                                        width:'100%',
                                        tableLayout:'fixed'
                                    }}>
                                        <td>{index + 1}.</td>
                                        <td>{row.result}</td>
                                        <td>{row.date}</td>
                                        <td>{row.time}</td>
                                        <td>
                                            <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            >
                                                <ActionBtn data-bs-toggle="modal" data-bs-target={`#model-${row._id}`}>
                                                    <FiEdit size="23px" color='green' style={{marginRight: "10px"}} />
                                                </ActionBtn>
                                                <ActionBtn>
                                                    <FiTrash2 size="23px" onClick={()=> deleteHandler(row._id)} color='red' />
                                                </ActionBtn>
                                            </div>
                                            <div className="modal fade" id={`model-${row._id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <form onSubmit={(e)=> {
                                                            e.preventDefault();
                                                            updateSubmitHandler(row._id)
                                                        }} customatt={`${row._id}`}>
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="staticBackdropLabel">Edit</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label htmlFor="" className="form-label">Result</label>
                                                                    <input type="number" step="any" className="form-control" id="" placeholder="result" onChange={(e)=> setResult(e.target.value)}/>
                                                                </div>
                                                                {/* <div className="mb-3">
                                                                    <label htmlFor="" className="form-label">Time</label>
                                                                    <select className="form-control custom-select" onChange={(e)=> setTime(e.target.value)}>
                                                                        <option>Choose</option>
                                                                        <option value="10:30 AM">10:30 AM</option>
                                                                        <option value="02:30 PM">02:30 PM</option>
                                                                        <option value="07:00 PM">07:00 PM</option>
                                                                        <option value="08:00 PM">08:00 PM</option>
                                                                        <option value="09:00 PM">09:00 PM</option>
                                                                    </select>
                                                                </div> */}
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeRef}>Close</button>
                                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>    
                                )
                            }
                        </tbody>
                    </table>
            </div>
       </div>
    {
        
        createPortal( loading && <DashboardLoading />, document.getElementById("portal"))
    }
    </>
  )
}

export default Create;
