import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {  useParams } from 'react-router-dom';
import { getMethod } from '../Apis/apis';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import EmptyRecord from '../utils/EmptyRecord';
import Header from './Header';

const Container = styled.div`
    max-width: 480px;
    min-height: 100vh;
    opacity: 1;
    background-color: ${props => props.theme.secondary};
    background-attachment: fixed;
    margin: 0 auto;
    position: relative;
`

const ContainerDiv = styled.div`
   padding: 0 20px 20px 20px;
`
const Ul = styled.ul`

`

const ResultNum = styled.p`
    font-weight: bolder;
    color: ${props => props.theme.fontColor};
    font-size: 35px;
    letter-spacing: .6px;
    margin-bottom: 0;
    width: 20%;
    text-align: center;
    background-color: ${props => props.theme.secondary};
    border-radius: 10px;
    margin-right: 10px;
    padding: 10px;
`

const ResultTitle = styled.li`
    padding: 10px 0;
    font-size: 20px;
    color: ${props => props.theme.fontColor};
    text-align: center;
    font-weight: bolder;
`

const Li = styled.li`
    padding: 5px 0;
    user-select: none;
    
    &:last-child {
        margin-bottom: 0;
    }
`

const TimeResultFlex = styled.div`
    display: flex;
    align-items: center; 
    color: #FFF;

    span {
      background-color: ${props => props.theme.secondary};
      color: #FFF;
      font-size: 25px;
      display: block;
      width: 80%;
      border-radius: 10px;
      text-align: center;
      padding: 17px;
      p {
          margin-bottom: 0;
          text-align: center;
        } 
    } 
`

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    td {
      border: 1px solid #e8e8e8;
      border-width: 0.2px;
      color: #e8e8e8;
      font-weight: 300;
      width: 50%;
      text-align: center;
      padding: 5px;
    }
`

const Result = () => {
  const [result,setResult] = useState([]);
  const [loading,setLoading] = useState(true);
  const date = useParams();

  useEffect(()=>{
    (async () =>{
      setLoading(true);
      let res = await getMethod(`/number/filter?date=${date.date}`);
      if(res){
        setLoading(false);
        if(res.con){
           if(res.data){
            setResult(res.data);
           }
        }
      };
    })();
  },[date]);

  return (
    <Container>
      <Header status={true} />
    <ContainerDiv>
    <ResultTitle>{date.date}</ResultTitle>
    {
      result.length > 0 ?
      <Table>
         <tbody>
             {
              result.map((el,i) => 
                <tr key={i}>
                  <td>{el.time ?? '??'}</td>
                  <td style={{color: '#f88c49'}}>{el.result ?? '??'}</td>
                </tr>
              )
             }
             
         </tbody>
      </Table>
      :
      <EmptyRecord message={`မှတ်တမ်းမရှိပါ`} />
    }
      {/* <Ul>
          <ResultTitle>{date.date}</ResultTitle>
          {
            result.length > 0 ?
            result.map((el,index) => 

            <Li>
              <TimeResultFlex>
                  {
                    <ResultNum>{el.result}</ResultNum>
                  }
                  <span>
                    <p>{el.time}</p>
                  </span>
              </TimeResultFlex>
            </Li>
            )
            :
            <EmptyRecord message={`မှတ်တမ်းမရှိပါ`} />
          }
      </Ul>    */}
      </ContainerDiv>
    {
        createPortal( loading && <Loading />, document.getElementById("portal"))
    }
    </Container>
  )
}
export default Result;