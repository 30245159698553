import React from 'react'
import { FiMenu } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { toggleHandler } from '../../store/sidebarToggleSlice';

const SidebarHeaderDiv = styled.div`
    padding: 24px; 
    text-transform: uppercase; 
    font-weight: bold; 
    font-size: 14px; 
    letter-spacing: 1px; 
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #FFF;
    box-shadow: 0 2px 4px 0 rgb(43 43 43 / 10%);   
    color: #000; 
    display: flex;
    align-items: center;
`

const ToggleIcon = styled.div`
  ${'' /* display: none; */}
  margin-right: 20px;
  ${'' /* @media (max-width: 768px) {
    display: block;
  } */}
`;

const Header = () => {
  const dispatch = useDispatch();
  const {value} = useSelector(state => state.sideBarToggle);
  const sidebarToggleHandler = () => {
      dispatch(toggleHandler(!value));
  }
  return (
    <SidebarHeaderDiv>
      <ToggleIcon>
        <FiMenu size="25px" onClick={sidebarToggleHandler} style={{cursor: 'pointer'}}/>
      </ToggleIcon>
      Admin Dashboard
    </SidebarHeaderDiv>
  )
}

export default Header
