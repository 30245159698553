import React from 'react';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import Header from './Header';
import Dashboard from './Dashboard';

const ContainerFlex = styled.div`
   display: flex;
   align-items: flex-start;
   height: 100vh;
`

const HeaderContentContainer = styled.div`
   width: 100%;
   height:100%;
   ${'' /* padding-left: 270px;
   @media (max-width: 768px) {
      padding-left: 0;
   } */}
`
const ContentContainerDiv = styled.div`
   padding: 20px;
   height:90%;
`
const Home = () => {
  return (
    <>
        <ContainerFlex>
            <Sidebar />
            <HeaderContentContainer>
                <Header />
                <ContentContainerDiv>
                    <Dashboard/>
                </ContentContainerDiv>
            </HeaderContentContainer>
        </ContainerFlex>
    </>
  )
}

export default Home
