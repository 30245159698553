import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom';
import styled from 'styled-components'
import { getMethod} from '../../Apis/apis';
import DashboardLoading from '../../utils/DashboardLoading';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate } from 'react-router-dom';

const H5 = styled.h5`
    margin-bottom: 0;
    color: #333;
    font-size: 15px;
    font-weight: 700;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.1;
    position: relative;
    &:after {
        content: "";
        background-color: #d2d2d2;
        width: 60px;
        height: 1px;
        position: absolute;
        bottom: -20px;
        left: 0;
`

const Dashboard = () => {
  const [data,setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
 
  useEffect(()=>{
    let token = localStorage.getItem("r_t_a");
        if(token){
            (async() => {
                let res = await getMethod(`/number`);
                if(res){
                    if(res.data){
                        setData(res.data);
                        setLoading(false);
                    }else{
                        setLoading(false);
                    };
                };
            })();
        }else{
            navigate("/admin/login");
        };
    },[]);


  return (
    <>
       <div className='card h-100'>
            <div className='card-header' style={{
                    backgroundColor: "transparent",
                    borderBottom: 'none',
                    padding: '20px',
                    position: 'relative'}}>
                <H5>2D Result</H5>
            </div>
            <div className='card-body'>
                <table className="table h-100">
                        <thead className="table-dark">
                            <tr style={{
                                display:'table',
                                width:'100%',
                                tableLayout:'fixed'
                            }}>
                                <th>No.</th>
                                <th>Result</th>
                                <th>Date</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody style={{
                            display: "block",
                            height:'90%',
                            overflow:'auto'
                        }}>
                            {
                                data.length > 0 &&
                                data.map((row,index) => 
                                    <tr key={index} 
                                    style={{
                                        display:'table',
                                        width:'100%',
                                        tableLayout:'fixed'
                                    }}>
                                        <td>{index + 1}.</td>
                                        <td>{row.result}</td>
                                        <td>{row.date}</td>
                                        <td>{row.time}</td>
                                    </tr>    
                                )
                            }
                        </tbody>
                </table>
            </div>
        </div>
    {
        
        createPortal( loading && <DashboardLoading />, document.getElementById("portal"))
    }
    </>
  )
}

export default Dashboard
