import React from 'react';
import ReactDOM from 'react-dom/client';
import {ThemeProvider} from 'styled-components';
import {BrowserRouter as Router} from "react-router-dom";
import App from './App';
import GlobalStyle from './styles/globalStyles';
import { store } from './store/store';
import { Provider } from 'react-redux';
import './index.css';

// document.addEventListener('contextmenu', (e) => e.preventDefault());
// function ctrlShiftKey(e, keyCode) {
//   return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
// }
// document.onkeydown = (e) => {
//   if (
//     e.keyCode === 123 ||
//     ctrlShiftKey(e, 'I') ||
//     ctrlShiftKey(e, 'J') ||
//     ctrlShiftKey(e, 'C') ||
//     (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
//   )
//     return false;
// };

const theme = {
    primary: "#2f3352 !important",
    secondary: "#2d3758 !important",
    subSecondary: "#00FF00 !important",
    fontColor: "#f88c49 !important"
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme = {theme}>
    <GlobalStyle />
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>
    </ThemeProvider>
);
